@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .theme-bg {
    @apply bg-gradient-to-r from-[#F61B13] to-[#EE0A60];
  }

  .team-member-image-container {
    @apply mt-[35px] md:m-0 lg:m-0 w-[250px] md:w-[430px] lg:w-[430px] rounded-[10px] overflow-hidden;
  }

  .team-member-small-image {
    @apply h-[60px] w-[60px] md:h-[120px] md:w-[120px] lg:h-[120px] lg:w-[120px] rounded-[10px] cursor-pointer hover:opacity-75;
  }

  .contact-form-input {
    @apply md:w-[534px] lg:w-[534px] p-[20px] text-[16px];
  }

  .footer-nav-link {
    @apply text-transparent bg-clip-text theme-bg hover:font-[600] transition-all hover:scale-105;
  }
}

input, textarea, button {
  outline-width: 0;
  outline: none;
  border: none;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Poppins, sans-serif;
}

body {
  background-color: #000000;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
